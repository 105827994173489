/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Link } from "react-router-dom";
import { setUser, useAppDispatch, useAppState } from "context";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "@firebase/auth";
import { toast } from "react-toastify";

const Header = ({ t }: any) => {
  const { user, auth } = useAppState();
  const dispatch = useAppDispatch();
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(true);
  };

  const onClose = () => {
    setVisibility(false);
  };

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(setUser(null));
        toast.success("Logged out successfully!");
      })
      .catch((err) => {
        console.warn(err);
        toast.error("An error occured. Please try again.");
      });
  };

  const MenuItem = ({ isDrawer = false }) => {
    return (
      <div
        style={
          isDrawer
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
            : undefined
        }
      >
        <a href="https://apps.apple.com/us/app/pureple-outfit-planner/id628106373">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
            style={{ height: "43px" }}
            alt="Download Pureple iphone/ipad"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.app.pureple">
          <img
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ height: 65, marginLeft: isDrawer ? 0 : 15 }}
            alt="Download Pureple Android"
          />
        </a>
        <CustomNavLinkSmall>
          <Link to="/about">
            <Span>{t("About")}</Span>
          </Link>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall>
          <a href="mailto:info@pureple.com">
            <Span>{t("Contact")}</Span>
          </a>
        </CustomNavLinkSmall>

        {user && !user.isAnonymous ? (
          <>
            <CustomNavLinkSmall>
              <Link to="/wardrobe">
                <Span>Wardrobe</Span>
              </Link>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall>
              <a onClick={handleLogOut}>
                <Span>
                  <LogoutOutlined />
                </Span>
              </a>
            </CustomNavLinkSmall>
          </>
        ) : (
          /*           <CustomNavLinkSmall>
            <Link to="/login">
              <Span>Login</Span>
            </Link>
          </CustomNavLinkSmall>

 */ <div />
        )}
      </div>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <div>
              <h2>pureple</h2>
            </div>
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem isDrawer />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
