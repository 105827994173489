const routes = [
  {
    path: ["/login"],
    component: "Login",
  },
  {
    path: ["/password-reset"],
    component: "PasswordReset",
  },
  {
    path: ["/create-account"],
    component: "CreateAccount",
  },
  {
    path: ["/wardrobe"],
    component: "Wardrobe",
  },
  {
    path: ["/privacy.aspx"],
    exact: true,
    component: "Privacy",
  },

  {
    path: ["/", "/about"],
    component: "Home",
  },
  {
    path: ["/community"],
    exact: true,
    component: "Community",
  },
  {
    path: ["/community/:shareId"],
    component: "SingleShare",
  },
];

export default routes;
