import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBmiSmWTHfhi_y7p8PMPUPaYwAxi5uznkk0",
  authDomain: "pureple-prod.firebaseapp.com",
  databaseURL: "https://pureple-prod.firebaseio.com",
  projectId: "pureple-prod",
  storageBucket: "pureple-prod.appspot.com",
  messagingSenderId: "157249670803",
  appId: "1:157249670803:web:4d4ac1e5de94a60bdd4766",
  measurementId: "G-YMDXQEX7XP",
};

export default initializeApp(firebaseConfig);
