import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { getAnalytics, Analytics } from "@firebase/analytics";
import { Auth, getAuth, signInAnonymously, User } from "@firebase/auth";
import { Firestore, getFirestore } from "@firebase/firestore";
import { FirebaseStorage, getStorage } from "@firebase/storage";
import firebaseApp from "firebaseApp";

const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);

type State = {
  analytics: Analytics;
  db: Firestore;
  storage: FirebaseStorage;
  auth: Auth;
  user: User | null;
  username: string;
};

const initialState: State = {
  analytics,
  db,
  storage,
  auth,
  user: null,
  username: "",
};

const AppStateContext = createContext(initialState);
const AppDispatchContext = createContext<Dispatch<any>>(() => null);

const SET_USER = "SET_USER";

export function setUser(user: User | null) {
  return {
    type: SET_USER,
    payload: user,
  };
}

function AppReducer(state: State, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
        username: payload ? payload.displayName ?? `Anon:${payload.uid}` : "",
      };
    default:
      throw new Error(`Invalid action type ${type}`);
  }
}

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    signInAnonymously(auth)
      .then(({ user }) => dispatch(setUser(user)))
      .catch((err) => console.warn(err));
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppDispatch() {
  return useContext(AppDispatchContext);
}
