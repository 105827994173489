import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { AppContextProvider } from "./context";
import Router from "./router";
import i18n from "./translation";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <AppContextProvider>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ToastContainer />
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  </AppContextProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
